<template>
  <div id="app" class="container">
    <div class="content">
      <!-- 用作入口文件 -->
      <WaterElectricityDeviceHistory></WaterElectricityDeviceHistory>
    </div>
  </div>
</template>

<script>
import WaterElectricityDeviceHistory from "@/components/WaterAndElectricity/WaterElectricityDeviceHistory.vue";
export default {
  components: {
    WaterElectricityDeviceHistory,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>