<template>
  <div id="app">
    <div class="container">
      <div id="main6"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  props: ['deviceDataInfo', 'deviceName'],
  data() {
    return {
      list: this.deviceDataInfo,
    }
  },
  watch: {
    deviceDataInfo(val) {
      console.log(val);
      this.list = val
      console.log(this.list);
      this.initCharts()
    }
  },
  created() {

    // 获取当日
    let beginTime = this.$moment().format("YYYY-MM-DD 00:00:00");
    let endTime = this.$moment().format("YYYY-MM-DD 23:59:59");
    console.log([beginTime, endTime]);
    // 获取昨日
    let beginTime1 = this.$moment()
      .subtract(1, "days")
      .format("YYYY-MM-DD 00:00:00");
    let endTime1 = this.$moment()
      .subtract(1, "days")
      .format("YYYY-MM-DD 23:59:59");
    console.log([beginTime1, endTime1]);

    // 获取当前月
    console.log([this.$moment().startOf('month').format('YYYY-MM-DD'), this.$moment().endOf('month').format('YYYY-MM-DD')]);
    // 获取上个月
    console.log([this.$moment(new Date()).subtract(1, 'months').startOf('month').format('YYYY-MM-DD'), this.$moment(new Date()).subtract(1, 'months').endOf('month').format('YYYY-MM-DD')])
  },
  mounted() {
    this.initCharts()

  },
  methods: {
    initCharts() {
      var chartDom = document.getElementById('main6');
      var myChart = echarts.init(chartDom);
      var option;

      // let base = +new Date(1968, 9, 3);
      // let oneDay = 24 * 3600 * 1000;
      // // let date = this.list.timeList;
      // let data = [Math.random() * 300];
      // for (let i = 1; i < 20000; i++) {
      //   var now = new Date((base += oneDay));
      //   // date.push([now.getFullYear(), now.getMonth() + 1, now.getDate()].join('/'));
      //   // data.push(Math.round((Math.random() - 0.5) * 20 + data[i - 1]));
      // }
      option = {
        tooltip: {
          trigger: 'axis',
          position: function (pt) {
            return [pt[0], '10%'];
          }
        },
        title: {
          left: 'center',
          text: this.list[0].deviceName,
          textStyle: {
            color: 'aqua'
          }
        },
        toolbox: {
          iconStyle: {
            color: "white",
          },
          feature: {
            dataView: { show: true, readOnly: false },
            dataZoom: {
              yAxisIndex: 'none'
            },
            restore: {},
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#fff'
            }
          },
          boundaryGap: false,
          data: this.list[0].timeList
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#fff'
            }
          },
          boundaryGap: [0, '100%']
        },
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 30
          },
          {
            start: 0,
            end: 30
          }
        ],
        series: [
          {
            // name: 'Fake Data',
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              color: 'rgb(129, 236, 236)'
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(129, 236, 236, 0)'
                },
                {
                  offset: 1,
                  color: 'rgb(129, 236, 236)'
                }
              ])
            },
            data: this.list[0].valueList
          }
        ]
      };

      option && myChart.setOption(option);
    }

  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  // background: rgba(214, 87, 87, 0.5);
  #main6 {
    width: 100%;
    height: 100%;
  }
}
</style>